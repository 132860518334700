.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
    width: 80%;
    margin: auto;
}
.link-section {
    margin-bottom: 20px;
}
.link-section.first {
    margin-top: 50px; /* Adjust this value to increase the top margin of the first link section */
}
.link-section h2 {
    font-size: 16px;
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
}
.link-section a {
    font-size: 14px;
    color: blue;
    text-decoration: none;
}
.link-section a:hover {
    text-decoration: underline;
}
